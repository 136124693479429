const INITIAL_STATE = {
    error: false,
    success: false,
    title: '',
    message: ''
}

const notificationReducer = (state = INITIAL_STATE, { type, title, message }) => {
    switch(type){
        case 'ERROR':
            return { ...state, error: true, title, message };
        case 'NOT_ERROR':
            return { ...state, error: false, title: '', message: '' };
        case 'SUCCESS':
            return { ...state, success: true, title, message };
        case 'NOT_SUCCESS':
            return { ...state, success: false, title: '', message: '' };
        default:
            return state;
    }
}

export default notificationReducer;