import Modal from 'react-bootstrap/Modal'
import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import http from './../../services/axios';
import { useDispatch } from 'react-redux';

export default function ProfessionalEdit(props) {
  const [name, setName] = useState(props.name);
  const [register, setRegister] = useState(props.reg);
  const [type, setType] = useState(props.type);

  const dispatch = useDispatch();

  const save = ()=>{
    props.onHide();
    http.user().update(props.reg, { name, reg: register, type })
      .then(result=>dispatch({type: 'UPDATE_USER', payload: result.data, options: { reg: props.reg } }))
      .catch(error=>dispatch({type: 'ERROR', title: `Erro ao editar profissional`, message: error.response.data}));
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Editar profissional
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Nome</Form.Label>
          <Form.Control id="formBasicName" value={ name } onChange={e=>setName(e.target.value)} type="text" placeholder="Digite o nome do profissional" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicRegister">
          <Form.Label>Registro</Form.Label>
          <Form.Control id="formBasicRegister" value={ register } onChange={e=>setRegister(e.target.value)} type="text" placeholder="Digite o registro do profissional" />
        </Form.Group>
        <Form.Group>
          <Form.Select value={type} onChange={e=>setType(e.target.value)}>
            <option value='prof'>Profissional</option>
            <option value='admin'>Administrador</option>
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onHide}>Voltar</Button>
        <Button onClick={save}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  );
}
  

  