import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import http from './../../services/axios';

export default function Login(){

    const dispatch = useDispatch();
    const [register, setRegister] = useState('');
    const logged = useSelector(state=>state.userState.logged);
    const typeUser = useSelector(state=>state.userState.user.type);

    const login = ()=>{
        if(!register) return dispatch({type: 'ERROR', title: 'Erro ao fazer login', message: 'Digite um registro válido!'});
        dispatch({type: 'LOADING'});
        http.user().auth({
            reg: register
        })
            .then(result=>{
                localStorage.setItem('account', JSON.stringify(result.data));
                dispatch({type: 'LOGIN', user: result.data.user, token: result.data.token});
            })
            .catch(error=>dispatch({type: 'ERROR', title: 'Erro ao fazer login', message: 'Registro não encontrado!'}))
            .finally(()=>dispatch({type: 'LOADED'}))
    }

    if(logged){
        if(typeUser === 'prof'){
            return <Redirect to='/agendar' />
        }
        if(typeUser === 'admin'){
            return <Redirect to='/admin/download' />
        }
    }

    return (
        <>
            <Container style={{maxWidth: '400px', width: '90%', height: '100%', color: '#fff'}}>
                <Row style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                    <Col style={{backgroundColor: '#067302', padding: '30px', borderRadius: '18px'}}>
                        <h1 style={{textAlign: 'center', fontSize: '28px'}}>Sistema ITEGUI<br /> Agenda ONLINE</h1>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Login</Form.Label>
                                <Form.Control value={register} onChange={e=>setRegister(e.target.value)} placeholder="Digite o seu login" />
                                </Form.Group>
                            <Button onClick={login} variant="dark" style={{width: '100%', textTransform: 'uppercase'}}>
                                Entrar
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}