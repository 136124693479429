import Modal from 'react-bootstrap/Modal'
import React, {useState, useEffect} from 'react';
import {Button, Form, FormSelect} from 'react-bootstrap';
import http from './../../services/axios';
import { useDispatch, useSelector } from 'react-redux';

export default function RoomInclude(props) {
  const [roomName, setRoomName] = useState('');
  const [locationId, setLocationId] = useState('');

  const dispatch = useDispatch();

  const locations = useSelector(state => state.locationState.locations);

  const save = ()=>{
    props.onHide();
    dispatch({type: 'LOADING'});
    http.room().create(locationId, roomName)
      .then(result=>dispatch({type: 'CREATE_ROOM', payload: result.data}))
      .catch(error=>dispatch({type: 'ERROR', title: `Erro ao criar sala`, message: error.response.data}))
      .finally(dispatch({type: 'LOADED'}))
  }
  useEffect(()=>{
    setRoomName('');
    setLocationId('');
  }, [props.onHide]);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Incluir sala
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <Form.Control type='text' value={roomName} onChange={e=>setRoomName(e.target.value)} placeholder='Digite o nome da sala' />
          </Form.Group>
          <Form.Group>
            <Form.Label>Local</Form.Label>
            <FormSelect type='text' value={locationId} onChange={e=>setLocationId(e.target.value)}>
              <option>Selecione o local...</option>
              {
                locations.map(({id, name})=>{
                  return <option key={id} value={id}>{name}</option>
                })
              }
            </FormSelect>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={props.onHide}>Voltar</Button>
          <Button onClick={save}>Salvar</Button>
        </Modal.Footer>
      </Modal>
    );
  }