import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Navbar, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import http from './../../services/axios';
import DateAdapter from '@mui/lab/AdapterMoment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import { TextField, Stack, MenuItem } from '@mui/material';
import 'moment/locale/pt-br';
import moment from 'moment-timezone';

function MaterialUIPickers({date, setDate}) {

  const handleChange = (newValue) => {
    setDate(moment(newValue).format('YYYY-MM-DD'));
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={moment.locale('pt-br')}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="Data"
          inputFormat="DD/MM/yyyy"
          value={date}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export default function ScheduleFilter({url}){
    
    const [register, setRegister] = useState('');

    const locations = useSelector(state => state.locationState.locations);
    const rooms = useSelector(state => state.roomState.rooms);

    const [returnOptions, setReturnOptions] = useState(false);
    const [date, setDate] = useState(moment().tz('America/Sao_Paulo').format('YYYY-MM-DD'));
    const [locationId, setLocationId] = useState(useSelector(state => state.scheduleState.selectedLocationId));
    const [roomId, setRoomId] = useState(useSelector(state => state.scheduleState.selectedRoomId));
    const dispatch = useDispatch();
    
    const logout = ()=>{
        localStorage.removeItem('account');
        dispatch({type: 'LOGOUT'});
        setReturnOptions(true);
    }

    useEffect(()=>{
        http.location().read()
            .then(result=>dispatch({type: 'LOADING_LOCATION', newLocations: result.data}))
            .catch(error=>dispatch({type: 'ERROR', title: `Erro ao carregar locais`, message: error.response.data}))
            .finally(dispatch({type: 'LOADED'}))
    }, []);

    useEffect(()=>{
        setRoomId(0);
        if(locationId != 0) http.room().readFromLocation(locationId)
            .then(result=>dispatch({type: 'LOADING_ROOM', payload: result.data}))
    }, [locationId]);

    useEffect(()=>{
        if(locationId && roomId) toSchedule();
    }, [roomId, date]);

    const toSchedule = ()=>{
        http.schedule().readAvailability({register, locationId, roomId, date})
            .then(result=>{
                dispatch({type: 'LOADING_HOURS_AVAILABILITY', payload: result.data.map(hour=>{ return { time: hour, status: 'vago'}}), options: {register, locationId, roomId, roomName: rooms.filter(room=>room.id === roomId)[0].name, date}});
            })
            .catch(error=>{
                dispatch({type: 'ERROR', title: `Erro ao filtrar horários`, message: error.response.data});
                setRoomId(0);
            })
            .finally(dispatch({type: 'LOADED'}));
    }

    const onBlur = ()=>{
        http.schedule().readAll({
            register
        })
            .then(result=>dispatch({type: 'LOADING_SCHEDULE', payload: result.data}))
    }

    return (
        <>
        {(returnOptions) ? <Redirect to='/login' /> : false}
        <Navbar expand={true} bg="dark" variant="dark" style={{textTransform: 'uppercase', margin: '5% 0', padding: '10px'}} >
            <Navbar.Collapse id="navbarScroll">
                <Container>
                    <Row>
                        <Col>
                            <p style={{color: 'white', textAlign: 'center', margin: '20px 0 30px'}}>Digite as informações para fazer o agendamento da sala:</p>
                        </Col>
                    </Row>
                    <Row xs={1} md={4} lg={7} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Col style={{display: `${url === '/admin/cronograma/agendar' ? 'block' : 'none'}`}}>
                                <Form.Group className="mb-3" controlId="formBasicEmail" style={{color: 'white'}}>
                                    <div style={{backgroundColor: 'white', padding: '10px', borderRadius: '15px'}}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Registro"
                                            value={register}
                                            onBlur={ ()=>(url === '/admin/cronograma/agendar') ? onBlur() : false }
                                            onChange={e=>setRegister(e.target.value != '' ? Number(e.target.value) : '')}
                                            fullWidth
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail" style={{color: 'white'}}>
                                    <div style={{backgroundColor: 'white', padding: '10px', borderRadius: '15px'}}>
                                        <MaterialUIPickers date={date} setDate={setDate} />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail" style={{color: 'white'}}>
                                    <div style={{backgroundColor: 'white', padding: '10px', borderRadius: '15px'}}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Local"
                                            value={locationId}
                                            onChange={e=>setLocationId(Number(e.target.value))}
                                            fullWidth
                                        >
                                            {[
                                                {id: 0, name: 'Selecione um local'}, ...locations]
                                                    .map((location) => (
                                                        <MenuItem key={location.id} value={location.id}>
                                                            {location.name}
                                                        </MenuItem>
                                                    )
                                                )
                                            }
                                        </TextField>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail" style={{color: 'white'}}>
                                    <div style={{backgroundColor: 'white', padding: '10px', borderRadius: '15px'}}>
                                        <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Sala"
                                                value={roomId}
                                                onChange={e=>setRoomId(Number(e.target.value))}
                                                fullWidth
                                            >
                                                {[
                                                    {id: 0, name: 'Selecione uma sala'}, ...rooms]
                                                        .sort((a, b)=>{
                                                            if(a.id === 0 || b.id === 0) return 0;
                                                            if (a.name < b.name) {
                                                            return -1;
                                                            }
                                                            if (a.name > b.name) {
                                                            return 1;
                                                            }
                                                            return 0;
                                                        })
                                                        .map((room) => (
                                                            <MenuItem key={room.id} value={room.id}>
                                                                {room.name}
                                                            </MenuItem>
                                                        )
                                                    )
                                                }
                                        </TextField>
                                    </div>
                                </Form.Group>
                            </Col>
                    </Row>
                    <Row>
                        <Col style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <Form.Group className="mb-3" controlId="formBasicEmail" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                <Link to='/admin/download' style={{display: `${url === '/admin/cronograma/agendar' ? 'block' : 'none'}`}}>
                                    <Button>Voltar</Button>
                                </Link>
                                <Button onClick={logout} style={{textTransform: 'uppercase', color: 'white', margin: '5%'}}>Sair</Button>
                            </Form.Group> 
                        </Col>
                    </Row>
                </Container>
            </Navbar.Collapse>
        </Navbar>
        </>
    );
}