import React, { useEffect } from 'react';
import Header from './../../Components/AdminHeader';
import { Container, Table, Button } from 'react-bootstrap';
import LocationInclude from '../../Components/LocationInclude';
import LocationDelete from '../../Components/LocationDelete';
import LocationEdit from '../../Components/LocationEdit';
import http from './../../services/axios';
import { useDispatch, useSelector } from 'react-redux';

export default function Location(){

  const dispatch = useDispatch();

  const locations = useSelector(state => state.locationState.locations);

  useEffect(()=>{
    http.location().read()
      .then(result=>{
        dispatch({type: 'LOADING_LOCATION', newLocations: result.data});
      })
  }, []);

    function IncluirLocal() {
        const [modalShow, setModalShow] = React.useState(false);
      
        return (
          <>
            <Button variant="success" style={{marginBottom: '2%', marginLeft: '3%', textTransform: 'uppercase'}} onClick={() => setModalShow(true)}>
              + Incluir
            </Button>
      
            <LocationInclude
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </>
        );
      }
      
    function EditarLocal({id, name}) {
        const [modalShow, setModalShow] = React.useState(false);
      
        return (
          <>
            <Button variant="dark" style={{marginBottom: '2%', textTransform: 'uppercase'}} onClick={() => setModalShow(true)}>
              Editar
            </Button>
      
            <LocationEdit
              show={modalShow}
              onHide={() => setModalShow(false)}
              id={id}
              name={name}
            />
          </>
        );
      }
    function DeletarLocal({id}) {
        const [modalShow, setModalShow] = React.useState(false);
      
        return (
          <>
            <Button variant="dark" style={{marginBottom: '2%', marginLeft: '1%', textTransform: 'uppercase'}} onClick={() => setModalShow(true)}>
              Deletar
            </Button>
      
            <LocationDelete
              show={modalShow}
              onHide={() => setModalShow(false)}
              id={id}
            />
          </>
        );
      }

    return (
        <>
            <Header />
            <Container>
                <h2 style={{textAlign: 'center', marginBottom: '40px', marginTop: '40px'}}>Controle de Locais</h2>
                <IncluirLocal />
                <Table responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                      <tbody>
                        {
                          locations.map(({id, name})=>{
                            return <tr key={id}>
                              <td>{id}</td>
                              <td>{name}</td>
                              <td>
                                <EditarLocal id={id} name={name} /> <DeletarLocal id={id} />
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                  </Table>
            </Container>
        </>
    );
}