import Modal from 'react-bootstrap/Modal'
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import http from './../../services/axios';

export default function LocationDelete(props) {

  const dispatch = useDispatch();

  const remove = ()=>{
    props.onHide();
    dispatch({type: 'LOADING'});
    http.location().destroy(props.id)
      .then(result=>dispatch({type: 'DELETE_LOCATION', locationId: props.id}))
      .catch(error=>dispatch({type: 'ERROR', title: `Erro ao deletar local ${props.id}`, message: error.response.data}))
      .finally(dispatch({type: 'LOADED'}));
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Deletar local
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>{`Deseja deletar o local ${props.id}?`}</Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onHide}>Voltar</Button>
        <Button onClick={remove}>Deletar</Button>
      </Modal.Footer>
    </Modal>
  );

}
  

  