import Modal from 'react-bootstrap/Modal'
import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import http from './../../services/axios';
import { useDispatch } from 'react-redux';

export default function LocationInclude(props) {

    const [locationName, setLocationName] = useState('');

    const dispatch = useDispatch();

    const save = ()=>{
      props.onHide();
      dispatch({type: 'LOADING'});
      http.location().create({name: locationName})
        .then(result=>dispatch({type: 'CREATE_LOCATION', newLocation: result.data}))
        .catch(error=>dispatch({type: 'ERROR', title: `Erro ao criar local`, message: error.response.data}))
        .finally(dispatch({type: 'LOADED'}))
    }

    useEffect(()=>{
      setLocationName('');
    }, [props.onHide]);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Incluir local
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Nome</Form.Label>
          <Form.Control id='locationName' type='text' value={locationName} onChange={e=>setLocationName(e.target.value)} placeholder='Digite o nome do local' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={props.onHide}>Voltar</Button>
          <Button onClick={save}>Salvar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  

  