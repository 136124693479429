import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import http from './../../services/axios';
import moment from 'moment';
import { useDispatch } from 'react-redux';

export default function DaysOffsetsInclude() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const dispatch = useDispatch();

    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

    const save = ()=>{
      handleClose();
      http.daysOffsets().create({
        date
      })
        .then(result=>{
          dispatch({type: 'CREATE_DAYS_OFFSETS', payload: result.data});
        })
        .catch(error=>dispatch({type: 'ERROR', title: `Erro ao adicionar data`, message: error.response.data}));
    }

    return (
      <>
        <Button variant="success" style={{marginBottom: '2%', marginLeft: '3%', textTransform: 'uppercase'}} onClick={handleShow}>
          + Incluir
        </Button>
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Incluir dia</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Label>Data</Form.Label>
              <Form.Control id='formBasicDate' name='date' type='date' value={date} onChange={e=>setDate(e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Voltar
            </Button>
            <Button variant="primary" onClick={()=>save()}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}