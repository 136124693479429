let env = 'production';
let host;

switch(env){
    case 'development':
        host = 'http://localhost:8888';
    break;
    case 'production':
        host = '';
    break;
}

module.exports = {
    host
};