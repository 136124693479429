import moment from 'moment';

const INITIAL_STATE = {
    schedules: [],
    hoursAvailability: [],
    selectedRegister: '',
    selectedDate: moment().format('YYYY-MM-DD'),
    selectedLocationId: 0,
    selectedRoomId: 0,
    selectedRoomName: ''
}

const scheduleReducer = (state = INITIAL_STATE, { type, payload = null, options }) => {
    switch(type){
        case 'CREATE_SCHEDULE':
            return { ...state, schedules: [payload, ...state.schedules], hoursAvailability: state.hoursAvailability.map(hour=>{
                    if(hour.time == moment(options.selectedHour, 'HH:mm').format('HH:mm')){
                        let newHour = hour;
                        newHour.id = payload.id;
                        newHour.status = "preenchida";
                        return newHour;
                    }
                    return hour;
                })
            };
        case 'LOADING_SCHEDULE':
            return { ...state, schedules: payload };
        case 'LOADING_HOURS_AVAILABILITY':
            return { ...state, hoursAvailability: payload, selectedRegister: options.register, selectedLocationId: options.locationId, selectedRoomId: options.roomId, selectedRoomName: options.roomName, selectedDate: options.date };
        case 'DELETE_SCHEDULE_ADMIN':
            return { ...state, schedules: state.schedules.filter(({id})=>id != payload) };
        case 'DELETE_SCHEDULE':
            return { ...state, hoursAvailability: !payload ? state.hoursAvailability : 
                state.hoursAvailability.map(hour=>{
                    if(hour.time==moment(payload.selectedHour, 'HH:mm').format('HH:mm')){
                        let newHour = hour;
                        newHour.status = "vago";
                        return newHour;
                    }
                    return hour;
                })
                , schedules: state.schedules.filter(schedule=>schedule.id != options.scheduleId)
                , hoursAvailability: state.hoursAvailability.map(hour=>{
                    if(hour.id == options.scheduleId){
                        let newHour = hour;
                        newHour.status = 'vago';
                        newHour.id = undefined;
                        return newHour;
                    }
                    return hour;
                })
            }
        default:
            return state;
    }
}

export default scheduleReducer;