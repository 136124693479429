import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import http from './../../services/axios';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import DateAdapter from '@mui/lab/AdapterMoment';
import { DesktopDatePicker, LocalizationProvider, DesktopTimePicker } from '@mui/lab';
import { TextField, Stack, MenuItem } from '@mui/material';
//import 'moment/locale/pt-br';

function MaterialUIPickers({date, setDate}) {

  const handleChange = (newValue) => {
    setDate(moment(newValue).format('YYYY-MM-DD'));
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={moment.locale('pt-br')}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="Implantação"
          inputFormat="DD/MM/yyyy"
          value={date}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}

function MaterialUITimePickers({time, setDate, label}) {

  const handleChange = (newValue) => {
    newValue ? moment(newValue).format('HH:mm') : alert('Olá mundo **************');
    setDate(newValue ? moment(newValue).format('HH:mm') : '');
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={moment.locale('pt-br')}>
      <Stack spacing={3}>
        <DesktopTimePicker
          label={ label }
          inputFormat="HH:mm"
          value={moment(time, 'HH:mm')}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export default function AvailabilyInclude() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const dispatch = useDispatch();

    const locations = useSelector(state => state.locationState.locations);
    const rooms = useSelector(state => state.roomState.rooms);

    const [day, setDay] = useState(-1);
    const [locationId, setLocationId] = useState(0);
    const [roomId, setRoomId] = useState(0);
    const [date, setDate] = useState(moment().tz('America/Sao_Paulo').format('YYYY-MM-DD'));

    const [timeBegin, setTimeBegin] = useState('');

    const [timeEnd, setTimeEnd] = useState('');

    const daysOfWeek = [
      {
        id: 0,
        name: 'Domingo'
      },
      {
        id: 1,
        name: 'Segunda-feira'
      },
      {
        id: 2,
        name: 'Terça-feira'
      },
      {
        id: 3,
        name: 'Quarta-feira'
      },
      {
        id: 4,
        name: 'Quinta-feira'
      },
      {
        id: 5,
        name: 'Sexta-feira'
      },
      {
        id: 6,
        name: 'Sábado'
      }
    ];

    const clearFields = ()=>{
      setDay(-1);
      setLocationId(0);
      setRoomId(0);
    }

    const closeModal = ()=>{
      handleClose();
      clearFields();
    }

    const save = ()=>{
      closeModal();
      http.availability().create({
        day,
        locationId,
        roomId,
        timeBegin,
        timeEnd,
        dateImplantation: date
      })
        .then(result=>{
          dispatch({type: 'CREATE_AVAILABILITY', payload: result.data});
        })
        .catch(error=>dispatch({type: 'ERROR', title: `Erro ao adicionar dia indiponível`, message: error.response.data}));
    }

    return (
      <>
        <Button variant="success" style={{marginBottom: '2%', marginLeft: '3%', textTransform: 'uppercase'}} onClick={handleShow}>
          + Incluir
        </Button>
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Incluir dia indisponível</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <TextField
                id="outlined-select-currency"
                label="Dia"
                value={day}
                select
                onChange={e=>setDay(e.target.value)}
                fullWidth
              >
                {[
                    {id: -1, name: 'Selecione um dia da semana'}, ...daysOfWeek]
                        .map((day) => (
                            <MenuItem key={day.id} value={day.id}>
                                {day.name}
                            </MenuItem>
                        )
                    )
                }
              </TextField>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <TextField
                id="outlined-select-currency"
                label="Local"
                value={locationId}
                select
                onChange={e=>{
                  setRoomId(0);
                  setLocationId(e.target.value);
                }}
                fullWidth
              >
                {[
                    {id: 0, name: 'Selecione um local'}, ...locations]
                        .map((location) => (
                            <MenuItem key={location.id} value={location.id}>
                                {location.name}
                            </MenuItem>
                        )
                    )
                }
              </TextField>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <TextField
                id="outlined-select-currency"
                label="Sala"
                value={roomId}
                select
                onChange={e=>setRoomId(e.target.value)}
                fullWidth
                disabled={locationId === 0 ? true : false}
              >
                {[
                    {id: 0, name: 'Selecione uma sala'},
                    ...rooms.filter(room=>room.locationId === locationId)
                  ]
                        .map((room) => (
                            <MenuItem key={room.id} value={room.id}>
                                {room.name}
                            </MenuItem>
                        )
                    )
                }
              </TextField>
            </Form.Group>
            <Form.Group className="mb-3">
              <MaterialUITimePickers time={ timeBegin } setDate={ setTimeBegin } label="Início" />
            </Form.Group>
            <Form.Group className="mb-3">
              <MaterialUITimePickers time={ timeEnd } setDate={ setTimeEnd } label="Término" />
            </Form.Group>
            <Form.Group className="mb-3">
              <MaterialUIPickers date={date} setDate={setDate} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Voltar
            </Button>
            <Button disabled={day < 0 ? true : false} variant="primary" onClick={()=>save()}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}