import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import http from './../../services/axios';
import { useDispatch } from 'react-redux';

export default function DaysOffsetsDelete({id, date}) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const dispatch = useDispatch();

    const remove = ()=>{
      handleClose();
      http.daysOffsets().destroy(id)
        .then(()=>{
          dispatch({type: 'DELETE_DAYS_OFFSETS', options: {id}});
        })
        .catch(error=>dispatch({type: 'ERROR', title: `Erro ao remover data`, message: error.response.data}));
    }

    return (
      <>
        <Button variant="dark" style={{marginBottom: '2%', marginLeft: '1%', textTransform: 'uppercase'}} onClick={handleShow}>
          Deletar
        </Button>
  
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Deletar dia</Modal.Title>
          </Modal.Header>
          <Modal.Body>{`Deseja deletar o dia ${date}?`}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Voltar
            </Button>
            <Button variant="primary" onClick={()=>remove()}>
              Deletar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}