import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from './../Login';
import Schedule from '../Schedule';
import TimeLine from '../TimeLine';
import Location from '../Location';
import Room from '../Room';
import Professional from '../Professional';
import DaysOffsets from '../DaysOffsets';
import Availability from '../Availability';
import TimeLineInclude from '../Schedule';

const Rotes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path='/login' component={ Login } />
            <Route exact path='/agendar' component={ Schedule } />
            <Route exact path='/admin/cronograma/agendar' component={ TimeLineInclude } />
            <Route exact path='/admin/download' component={ TimeLine } />
            <Route exact path='/admin/locais' component={ Location } />
            <Route exact path='/admin/salas' component={ Room } />
            <Route exact path='/admin/profissionais' component={ Professional } />
            <Route exact path='/admin/dias-ausentes' component={ DaysOffsets } />
            <Route exact path='/admin/disponibilidade' component={ Availability } />
        </Switch>
    </BrowserRouter>
);

export default Rotes;