const INITIAL_STATE = {
    locations: []
}

const locationReducer = (state = INITIAL_STATE, { type, newLocation, newLocations, locationId, payload }) => {
    switch(type){
        case 'CREATE_LOCATION':
            return { ...state, locations: [...state.locations, newLocation] };
        case 'GET':
            return { ...state };
        case 'LOADING_LOCATION':
            return { ...state, locations: newLocations };
        case 'DELETE_LOCATION':
            return { ...state, locations: state.locations.filter(location=>location.id != locationId) }
        case 'UPDATE_LOCATION':
            return { ...state, locations: state.locations.map(location=>{
                if(location.id === locationId){
                    let newDataLocation = location;
                    Object.keys(payload).forEach((field, index) => {
                        newDataLocation[field] = payload[field];
                    });
                    return newDataLocation;
                }
                return location;
                })
            }
        default:
            return state;
    }
}

export default locationReducer;