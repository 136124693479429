import React, { useEffect } from 'react';
import Header from './../../Components/AdminHeader';
import { Container, Table, Button } from 'react-bootstrap';
import ProfessionalInclude from '../../Components/ProfessionalInclude';
import ProfessionalDelete from '../../Components/ProfessionalDelete';
import ProfessionalEdit from '../../Components/ProfessionalEdit';
import { useDispatch, useSelector } from 'react-redux';
import http from './../../services/axios';

export default function Professional(){
  const dispatch = useDispatch();

  const users = useSelector(state => state.userState.users);

  useEffect(()=>{
    http.user().read()
      .then(result=>{
        dispatch({type: 'LOADING_USER', payload: result.data});
        console.log('Lista users:', result.data, users);
      })
  }, []);

    function IncluirProfessional() {
        const [modalShow, setModalShow] = React.useState(false);
      
        return (
          <>
            <Button variant="success" style={{marginBottom: '2%', marginLeft: '3%', textTransform: 'uppercase'}} onClick={() => setModalShow(true)}>
              + Incluir
            </Button>
      
            <ProfessionalInclude
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </>
        );
      }
      
    function EditarProfessional({reg, name, type}) {
        const [modalShow, setModalShow] = React.useState(false);
      
        return (
          <>
            <Button variant="dark" style={{marginBottom: '2%', textTransform: 'uppercase'}} onClick={() => setModalShow(true)}>
              Editar
            </Button>
      
            <ProfessionalEdit
              show={modalShow}
              onHide={() => setModalShow(false)}
              reg={reg}
              name={name}
              type={type}
            />
          </>
        );
      }

    function DeletarProfessional({reg}) {
        const [modalShow, setModalShow] = React.useState(false);
      
        return (
          <>
            <Button variant="dark" style={{marginBottom: '2%', marginLeft: '1%', textTransform: 'uppercase'}} onClick={() => setModalShow(true)}>
              Deletar
            </Button>
      
            <ProfessionalDelete
              show={modalShow}
              onHide={() => setModalShow(false)}
              reg={reg}
            />
          </>
        );
      }

    useEffect(()=>{
      http.user().read()
        .then(result=>dispatch({type: 'LOADING_USER', payload: result.data }))
        .catch(error=>dispatch({type: 'ERROR', title: `Erro ao carregar profissionais`, message: error.response.data}));
    }, []);

    return (
        <>
            <Header />
            <Container>
                <h2 style={{textAlign: 'center', marginBottom: '40px', marginTop: '40px'}}>Controle de Profissionais</h2>
                <IncluirProfessional />
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Registro</th>
                            <th>Nome</th>
                            <th>Tipo</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                      <tbody>
                        {
                          users.map(user=>{
                            return <tr key={user.id}>
                              <td>{ user.reg }</td>
                              <td>{ user.name }</td>
                              <td>{ user.type == 'admin' ? 'Administrador' : 'Profissional' }</td>
                              <td>
                                <EditarProfessional reg={user.reg} name={user.name} type={user.type} /> <DeletarProfessional reg={ user.reg } />
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                  </Table>
            </Container>
        </>
    );
}