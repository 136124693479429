import Modal from 'react-bootstrap/Modal'
import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import http from './../../services/axios';
import { useDispatch } from 'react-redux';

export default function CancellationSchedule(props) {

    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const cancellation = ()=>{
      handleClose();
      if(props.url === '/admin/cronograma/agendar'){
        http.schedule().cancellationAdmin(props.id)
          .then(()=>dispatch({type: 'DELETE_SCHEDULE', options: { scheduleId: props.id, dateCanceled: props.dateCanceled }}))
          .catch(error=>dispatch({type: 'ERROR', title: `Erro ao cancelar agendamento`, message: error.response.data}));
        return null;
      }
      http.schedule().cancellation(props.id)
        .then(()=>dispatch({type: 'DELETE_SCHEDULE', options: { scheduleId: props.id, dateCanceled: props.dateCanceled }}))
        .catch(error=>dispatch({type: 'ERROR', title: `Erro ao cancelar agendamento`, message: error.response.data}));
    }

    return (
      <>
        <Button variant="dark" style={{textTransform: 'uppercase'}} onClick={handleShow}>
          Cancelar
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmação</Modal.Title>
          </Modal.Header>
          <Modal.Body>Deseja realmente cancelar o agendamento?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
             Voltar
            </Button>
            <Button variant="primary" onClick={cancellation}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  