import React, { useEffect } from 'react';
import Header from './../../Components/AdminHeader';
import { Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import http from './../../services/axios';
import moment from 'moment';
import Delete from './../../Components/AvailabilityDelete';
import Include from './../../Components/AvailabilityInclude';
import dateFn from './../../utils/date';

export default function Availabily(){
  const dispatch = useDispatch();

  const availabilities = useSelector(state => state.availabilityState.availabilities);
  const locations = useSelector(state => state.locationState.locations);
  const rooms = useSelector(state => state.roomState.rooms);
  
  useEffect(()=>{
    http.availability().read()
      .then(result=>{
        dispatch({type: 'LOADING_AVAILABILITIES', payload: result.data});
      });
    http.location().read()
      .then(result=>{
        dispatch({type: 'LOADING_LOCATION', newLocations: result.data});
      });
    http.room().read()
      .then(result=>{
        dispatch({type: 'LOADING_ROOM', payload: result.data});
      });
  }, []);

  return (
      <>
          <Header />
          <Container>
              <h2 style={{textAlign: 'center', marginBottom: '40px', marginTop: '40px'}}>Disponibilidade</h2>
              <Include />
              <Table responsive>
                  <thead>
                      <tr>
                          <th>Dia</th>
                          <th>Local</th>
                          <th>Sala</th>
                          <th>Início</th>
                          <th>Término</th>
                          <th>Implantação</th>
                          <th>Ações</th>
                      </tr>
                  </thead>
                    <tbody>
                      {
                        availabilities
                          .map(availability=>{
                            let newObj = availability;
                            newObj.dayDescription = dateFn.getDayWeek(availability.day);
                            newObj.locationName = availability.locationId && availability.locationId > 0 && locations.length > 0 ?
                            locations.filter(({id})=>id == availability.locationId)[0].name :
                            '';
                            newObj.roomName = availability.roomId && availability.roomId > 0 && rooms.length > 0 ?
                              rooms.filter(({id})=>id == availability.roomId)[0].name :
                              '';
                            return newObj;
                          })
                          .sort((a, b)=>{
                            if (a.day < b.day) {
                              return -1;
                            }
                            if (a.day > b.day) {
                              return 1;
                            }
                            return 0;
                          })
                          .sort((a, b)=>{
                            if(a.day !== b.day) return 0;
                            if (a.locationName < b.locationName) {
                              return -1;
                            }
                            if (a.locationName > b.locationName) {
                              return 1;
                            }
                            return 0;
                          })
                          .sort((a, b)=>{
                            if(a.day !== b.day || a.locationName !== b.locationName) return 0;
                            if (a.roomName < b.roomName) {
                              return -1;
                            }
                            if (a.roomName > b.roomName) {
                              return 1;
                            }
                            return 0;
                          })
                          .sort((a, b)=>{
                            if(a.day !== b.day
                              || a.locationName !== b.locationName
                              || a.roomName !== b.roomName) return 0;
                            if (a.timeBegin < b.timeBegin) {
                              return -1;
                            }
                            if (a.timeBegin > b.timeBegin) {
                              return 1;
                            }
                            return 0;
                          })
                          .sort((a, b)=>{
                            if(a.day !== b.day
                              || a.locationName !== b.locationName
                              || a.roomName !== b.roomName
                              || a.timeBegin !== b.timeBegin) return 0;
                            if (a.timeEnd < b.timeEnd) {
                              return -1;
                            }
                            if (a.timeEnd > b.timeEnd) {
                              return 1;
                            }
                            return 0;
                          })
                          .map(availability=>{

                            return <tr key={availability.id}>
                              <td>{ availability.dayDescription }</td>
                              <td>
                                { availability.locationName }
                              </td>
                              <td>
                                { availability.roomName }
                              </td>
                              <td>
                                {
                                  availability.timeBegin ?
                                    moment(availability.timeBegin, 'HH:mm').format('HH:mm') : '' 
                                }
                              </td>
                              <td>
                                {
                                  availability.timeEnd ?
                                    moment(availability.timeEnd, 'HH:mm').format('HH:mm') : '' 
                                }
                              </td>
                              <td>{ moment(availability.dateImplantation, 'YYYY-MM-DD').format('DD/MM/YYYY')  }</td>
                              <td>
                                <Delete id={availability.id} day={ dateFn.getDayWeek(availability.day) } />
                              </td>
                            </tr>
                          })
                      }
                    </tbody>
                  </Table>
          </Container>
      </>
  );
}