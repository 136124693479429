import React, { useEffect } from 'react';
import Header from './../../Components/AdminHeader';
import { Container, Col, Row, Button } from 'react-bootstrap';
import Filter from './../../Components/TimeLineFilter';
import http from './../../services/axios';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Delete from './../../Components/TimeLineDelete';
import { Link } from 'react-router-dom';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses
} from '@mui/x-data-grid';

function CustomToolbar() {
  return (
    <Container>
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ utf8WithBom: true, fileName: 'tab_agenda' }} />
      </GridToolbarContainer>
    </Container>
  );
}

export default function TimeLine(){

  const dispatch = useDispatch();

  const schedules = useSelector(state => state.scheduleState.schedules.map(schedule=>{
    let objJSON = {};
    objJSON.id = schedule.id;
    objJSON.data = moment(schedule.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    objJSON.reg = schedule.userReg;
    objJSON.location = schedule.locationName;
    objJSON.room = schedule.roomName;
    objJSON.time = moment(schedule.time, 'HH:mm:ss').format('HH:mm');
    objJSON.name = schedule.userName;

    return objJSON;
  }));

  useEffect(()=>{
    http.schedule().readAll({ dateBegin: '', dateEnd: '', locationId: 0, roomId: 0 })
      .then(result=>dispatch({type: 'LOADING_SCHEDULE', payload: result.data}))
      .catch(error=>dispatch({type: 'ERROR', title: `Erro ao buscar agenda`, message: error.response.data}));
  }, []);

  const columns = [
    { field: 'data', headerName: 'DATA', width: 100 },
    { field: 'reg', headerName: 'REGISTRO', width: 120 },
    { field: 'location', headerName: 'LOCAL', width: 160 },
    { field: 'room', headerName: 'SALA', width: 100 },
    { field: 'time', headerName: 'HORA', width: 100 },
    { field: 'name', headerName: 'NOME', width: 160 },
    //{ field: 'actions', headerName: 'Ações', width: 160},
    {
      field: 'actions',
      headerName: 'Ações',
      type: 'actions',
      width: 160,
      getActions: (params) => [
        <Delete id={params.id} />,
      ],
    },
  ];

  return (
    <>
      <Header />
      <Filter />
      <h2 style={{textAlign: 'center', marginBottom: '40px', marginTop: '40px'}}>Cronograma</h2>
      <DataGrid rows={schedules} columns={columns} 
      components={{Toolbar: CustomToolbar}}
      componentsProps={{
        columnMenu: { background: 'red', counter: 1 },
      }}
      />
    </>
  );
}