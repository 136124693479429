import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import http from './../../services/axios';

export default function TimeLineDelete(props) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const dispatch = useDispatch();

    const remove = ()=>{
      handleClose();
      http.schedule().cancellationAdmin(props.id)
      .then(result=>dispatch({type: 'DELETE_SCHEDULE_ADMIN', payload: props.id}))
      .catch(error=>dispatch({type: 'ERROR', title: `Erro ao deletar agendamento`, message: error.response.data}))
    }

    return (
      <>
        <Button style={{fontSize: '16px', textTransform: 'uppercase'}} variant="dark" onClick={handleShow}>
          Deletar
        </Button>
  
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Deletar agendamento</Modal.Title>
          </Modal.Header>
          <Modal.Body>Deseja deletar o agendamento {props.id}?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Voltar
            </Button>
            <Button variant="primary" onClick={remove}>
              Deletar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}