import React, {useEffect} from 'react';
import Header from './../../Components/AdminHeader';
import { Container, Table, Button } from 'react-bootstrap';
import RoomInclude from '../../Components/RoomInclude';
import RoomDelete from '../../Components/RoomDelete';
import RoomEdit from '../../Components/RoomEdit';
import http from './../../services/axios';
import { useDispatch, useSelector } from 'react-redux';

export default function Location(){

  const dispatch = useDispatch();

  const rooms = useSelector(state => state.roomState.rooms);
  const locations = useSelector(state => state.locationState.locations);
 
  useEffect(()=>{
    http.location().read()
      .then(result=>{
        dispatch({type: 'LOADING_LOCATION', newLocations: result.data});
      })
  }, []);

  useEffect(()=>{
    http.room().read()
      .then(result=>{
        dispatch({type: 'LOADING_ROOM', payload: result.data});
      })
  }, []);

    function IncluirSala() {
        const [modalShow, setModalShow] = React.useState(false);
      
        return (
          <>
            <Button variant="success" style={{marginBottom: '2%', marginLeft: '3%', textTransform: 'uppercase'}} onClick={() => setModalShow(true)}>
              + Incluir
            </Button>
      
            <RoomInclude
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </>
        );
      }
      
    function EditarSala({id, name, locationId}) {
        const [modalShow, setModalShow] = React.useState(false);
      
        return (
          <>
            <Button variant="dark" style={{marginBottom: '2%', textTransform: 'uppercase'}} onClick={() => setModalShow(true)}>
              Editar
            </Button>
      
            <RoomEdit
              show={modalShow}
              onHide={() => setModalShow(false)}
              id={id}
              name={name}
              locationId={locationId}
            />
          </>
        );
      }
    function DeletarSala({id}) {
        const [modalShow, setModalShow] = React.useState(false);
      
        return (
          <>
            <Button variant="dark" style={{marginBottom: '2%', marginLeft: '1%', textTransform: 'uppercase'}} onClick={() => setModalShow(true)}>
              Deletar
            </Button>
      
            <RoomDelete
              show={modalShow}
              onHide={() => setModalShow(false)}
              id={id} 
            />
          </>
        );
      }

    return (
        <>
            <Header />
            <Container>
                <h2 style={{textAlign: 'center', marginBottom: '40px', marginTop: '40px'}}>Controle de Salas</h2>
                <IncluirSala />
                <Table responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th>Local</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                      <tbody>
                        {
                          rooms
                            .map(room=>{
                              let newObj = room;
                              newObj.locationName = locations.filter(location=>location.id === Number(room.locationId))[0].name;
                              return newObj;
                            })
                            .sort((a, b)=>{
                              if (a.locationName < b.locationName) {
                                return -1;
                              }
                              if (a.locationName > b.locationName) {
                                return 1;
                              }
                              return 0;
                            })
                            .sort((a, b)=>{
                              if(a.locationName !== b.locationName) return 0;

                              if(a.name < b.name) return -1;
                              if(a.name > b.name) return 1;

                              return 0;
                            })
                            .map(rooms=>{
                            return <tr key={rooms.id}>
                              <td>{rooms.id}</td>
                              <td>{rooms.name}</td>
                              <td>{rooms.locationName}</td>
                              <td>
                                <EditarSala id={rooms.id} name={rooms.name} locationId={rooms.locationId} /> <DeletarSala id={rooms.id} />
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                  </Table>
            </Container>
        </>
    );
}