import Modal from 'react-bootstrap/Modal'
import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import http from './../../services/axios';
import { useDispatch, useSelector } from 'react-redux';

export default function RoomEdit(props) {
  const [name, setName] = useState(props.name);
  const [locationId, setLocationId] = useState(props.locationId);

  const locations = useSelector(state => state.locationState.locations);

  const dispatch = useDispatch();

    const save = ()=>{
      props.onHide();
      dispatch({type: 'LOADING'});
      http.room().update(props.id, {name: name, locationId: locationId})
        .then(result=>dispatch({type: 'UPDATE_ROOM', payload: result.data, options: { roomId: props.id }}))
        .catch(error=>dispatch({type: 'ERROR', title: `Erro ao editar sala ${props.id}`, message: error.response.data}))
        .finally(dispatch({type: 'LOADED'}))
    }

    useEffect(()=>{
      setName(props.name);
      setLocationId(props.locationId);
    }, [props.onHide]);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Editar sala
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <Form.Control type='text' value={name} onChange={e=>setName(e.target.value)} placeholder='Digite o nome da sala' />
          </Form.Group>
          <Form.Group>
            <Form.Label>Local</Form.Label>
            <Form.Select type='text' value={locationId} onChange={e=>setLocationId(e.target.value)}>
                {
                    locations.map(({id, name})=>{
                      return <option key={id} value={id}>{name}</option>
                  })
                }
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={props.onHide}>Voltar</Button>
          <Button onClick={save}>Salvar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  

  