/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { host } from './../../urls';

export default {
    
    location: ()=>{
        let objJSON = {};

        const token = JSON.parse(localStorage.getItem('account')).token;

        const create = (name)=>{
            return axios.post(`${host}/location`, name, {
                headers: {
                    auth: token
                }
            });
        }

        const read = ()=>{
            return axios.get(`${host}/location`, {
                headers: {
                    auth: token
                }
            });
        }

        const update = (id, data)=>{
            return axios.put(`${host}/location/${id}`, data, {
                headers: {
                    auth: token
                }
            });
        }

        const destroy = (id)=>{
            return axios.delete(`${host}/location/${id}`, {
                headers: {
                    auth: token
                }
            });
        }

        objJSON.create = create;
        objJSON.read = read;
        objJSON.update = update;
        objJSON.destroy = destroy;

        return objJSON;
    },
    room: ()=>{
        let objJSON = {};

        const token = JSON.parse(localStorage.getItem('account')).token;

        const create = (id, name)=>{
            return axios.post(`${host}/location/${id}/room`, {
                name
            }, {
                headers: {
                    auth: token
                }
            });
        }

        const read = ()=>{
            return axios.get(`${host}/location/room`, {
                headers: {
                    auth: token
                }
            });
        }

        const readFromLocation = (locationId)=>{
            return axios.get(`${host}/location/${locationId}/room`, {
                headers: {
                    auth: token
                }
            });
        }

        const update = (id, data)=>{
            return axios.put(`${host}/location/room/${id}`, data, {
                headers: {
                    auth: token
                }
            });
        }

        const destroy = (id)=>{
            return axios.delete(`${host}/location/room/${id}`, {
                headers: {
                    auth: token
                }
            });
        }

        objJSON.create = create;
        objJSON.read = read;
        objJSON.readFromLocation = readFromLocation;
        objJSON.update = update;
        objJSON.destroy = destroy;

        return objJSON;
    },
    user: ()=>{
        let objJSON = {};

        const token = (localStorage.getItem('account')) ?
             JSON.parse(localStorage.getItem('account')).token : null;

        const auth = (data)=>{
            return axios.post(`${host}/user/auth`, data);
        }

        const create = (objJSON)=>{
            return axios.post(`${host}/user`, objJSON, {
                headers: {
                    auth: token
                }
            });
        }

        const update = (reg, data)=>{
            return axios.put(`${host}/user/${reg}`, data, {
                headers: {
                    auth: token
                }
            });
        }

        const destroy = (reg)=>{
            return axios.delete(`${host}/user/${reg}`, {
                headers: {
                    auth: token
                }
            });
        }

        const logout = ()=>{
            return axios.get(`${host}/user/logout`, {
                headers: {
                    auth: token
                }
            });
        }

        const read = ()=>{
            return axios.get(`${host}/user`, {
                headers: {
                    auth: token
                }
            });
        }

        objJSON.auth = auth;
        objJSON.logout = logout;
        objJSON.read = read;
        objJSON.create = create;
        objJSON.destroy = destroy;
        objJSON.update = update;
        
        return objJSON;

    },
    schedule: ()=>{
        let objJSON = {};

        const token = JSON.parse(localStorage.getItem('account')).token;

        const create = (objJSON)=>{
            return axios.post(`${host}/schedule`, objJSON, {
                headers: {
                    auth: token
                }
            });
        }

        const readAvailability = (params)=>{
            return axios.get(`${host}/schedule/availability`, {
                params,
                headers: {
                    auth: token
                }
            });
        }

        const readAll = (params)=>{
            return axios.get(`${host}/schedule`, {
                params,
                headers: {
                    auth: token
                }
            });
        }

        const readUser = ()=>{
            return axios.get(`${host}/schedule/user`, {
                headers: {
                    auth: token
                }
            });
        }

        const cancellation = (id)=>{
            return axios.delete(`${host}/schedule/${id}/user`, {
                headers: {
                    auth: token
                }
            });
        }

        const cancellationAdmin = (id)=>{
            return axios.delete(`${host}/schedule/${id}`, {
                headers: {
                    auth: token
                }
            });
        }

        objJSON.create = create;
        objJSON.readUser = readUser;
        objJSON.cancellation = cancellation;
        objJSON.readAvailability = readAvailability;
        objJSON.readAll = readAll;
        objJSON.cancellationAdmin = cancellationAdmin;
        
        return objJSON;

    },
    daysOffsets: ()=>{
        let objJSON = {};

        const token = JSON.parse(localStorage.getItem('account')).token;

        const create = (objJSON)=>{
            return axios.post(`${host}/daysoffsets`, objJSON, {
                headers: {
                    auth: token
                }
            });
        }

        const read = ()=>{
            return axios.get(`${host}/daysoffsets`, {
                headers: {
                    auth: token
                }
            });
        }

        const destroy = (id)=>{
            return axios.delete(`${host}/daysoffsets/${id}`, {
                headers: {
                    auth: token
                }
            });
        }

        objJSON.create = create;
        objJSON.read = read;
        objJSON.destroy = destroy;

        return objJSON;
    },
    availability: ()=>{
        let objJSON = {};

        const token = JSON.parse(localStorage.getItem('account')).token;

        const create = (objJSON)=>{
            return axios.post(`${host}/availability`, objJSON, {
                headers: {
                    auth: token
                }
            });
        }

        const read = ()=>{
            return axios.get(`${host}/availability`, {
                headers: {
                    auth: token
                }
            });
        }

        const destroy = (id)=>{
            return axios.delete(`${host}/availability/${id}`, {
                headers: {
                    auth: token
                }
            });
        }

        objJSON.create = create;
        objJSON.read = read;
        objJSON.destroy = destroy;

        return objJSON;
    }
}
