const INITIAL_STATE = {
    load: false
}

const loadingReducer = (state = INITIAL_STATE, { type }) => {
    switch(type){
        case 'LOADING':
            return { ...state, load: true };
        case 'LOADED':
            return { ...state, load: false };
        default:
            return state;
    }
}

export default loadingReducer;