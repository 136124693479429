const INITIAL_STATE = {
    availabilities: []
}

const availabilitiesReducer = (state = INITIAL_STATE, { type, payload, options }) => {
    switch(type){
        case 'CREATE_AVAILABILITY':
            return { ...state, availabilities: [...state.availabilities, payload] };
        case 'LOADING_AVAILABILITIES':
            return { ...state, availabilities: payload };
        case 'DELETE_AVAILABILITY':
            return { ...state, availabilities: state.availabilities.filter(availability=>availability.id != options.id) }
        default:
            return state;
    }
}

export default availabilitiesReducer;