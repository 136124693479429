import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        >
        <Modal.Header style={{backgroundColor: '#F8D7DA'}} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {
                useSelector(state => state.notificationState.title)
            }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: '#F8D7DA'}}>
            <p>
                {
                    useSelector(state => state.notificationState.message)
                }
            </p>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#F8D7DA'}}>
            <Button variant='dark' onClick={props.onHide}>Fechar</Button>
        </Modal.Footer>
        </Modal>
    );
}

export default function Error({title, message, isError, setIsError}) {

    const dispatch = useDispatch();

    return (
        <>
            <MyVerticallyCenteredModal
                show={useSelector(state => state.notificationState.error)}
                onHide={() => dispatch({type: 'NOT_ERROR'})}
                title={title}
                message={message}
            />
        </>
    );
}