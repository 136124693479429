import React, {useState, useEffect} from 'react';
import { Table, Container, Button, Row, Col, Badge, Spinner } from 'react-bootstrap';
import Header from './../../Components/ProfessionalHeader';
import Filter from './../../Components/ScheduleFilter';
import CancellationSchedule from '../../Components/CancellationSchedule';
import { Icon } from '@iconify/react';
import http from './../../services/axios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import './style.css';
import { Redirect } from 'react-router-dom';
import { isValid } from './../../utils/token';
import dateFn from './../../utils/date';

export default function Schedule(props){

    const dispatch = useDispatch();

    const listSchedule = useSelector(state => state.scheduleState.schedules);
    const hoursAvailability = useSelector(state => state.scheduleState.hoursAvailability);
    const register = useSelector(state => state.scheduleState.selectedRegister);
    const locationId = useSelector(state => state.scheduleState.selectedLocationId);
    const roomId = useSelector(state => state.scheduleState.selectedRoomId);
    const roomName = useSelector(state => state.scheduleState.selectedRoomName);
    const date = useSelector(state => state.scheduleState.selectedDate);

    const userName = JSON.parse(localStorage.getItem('account')).user.name;

    const [loading, setLoading] = useState('10:00:00');

    const locations = useSelector(state => state.locationState.locations);

    const toSchedule = (time)=>{
        setLoading(time);
        http.schedule().create({ register, date, time, locationId, roomId, userName })
            .then(result=>{
                dispatch({type: 'CREATE_SCHEDULE', payload: result.data, options: { selectedHour: time }});
            })
            .catch(error=>dispatch({type: 'ERROR', title: `Erro ao agendar`, message: error.response.data}))
            .finally(()=>setLoading(''));
    }

    const cancellation = (id, time)=>{
        if(props.match.url === '/admin/cronograma/agendar'){
            http.schedule().cancellationAdmin(id)
                .then(()=>dispatch({type: 'DELETE_SCHEDULE', payload: { selectedHour: time}, options: { scheduleId: id }}))
                .catch(error=>dispatch({type: 'ERROR', title: `Erro ao cancelar agendamento`, message: error.response.data}))
        } else{
            http.schedule().cancellation(id)
                .then(()=>dispatch({type: 'DELETE_SCHEDULE', payload: { selectedHour: time}, options: { scheduleId: id }}))
                .catch(error=>dispatch({type: 'ERROR', title: `Erro ao cancelar agendamento`, message: error.response.data}))
        }
        
    }

    useEffect(()=>{
        http.schedule().readUser()
            .then(result=>dispatch({type: 'LOADING_SCHEDULE', payload: result.data}))
            .catch(error=>dispatch({type: 'ERROR', title: `Erro ao carregar local`, message: error.response.data}))
            .finally(dispatch({type: 'LOADED'}));
    }, []);

    return (
        <>
            {(!isValid()) ? <Redirect to='/login' /> : false}
            <Header />
            <Container>
            <Stack display='flex' flexDirection='row-reverse'>
                <Chip
                    avatar={<Avatar />}
                    label={ userName }
                    variant="outlined"
                    color="success"
                    />
                </Stack>
                <Filter url={props.match.url} />
                <h2 style={{textAlign: 'center', marginBottom: '40px', marginTop: '40px'}}>Disponibilidade</h2>
                <Container fluid="md">
                    <Row>
                        <Col style={{wordBreak: 'break-all'}}>
                            {
                                (register) ? <Badge bg="danger" style={{marginRight: '10px', fontSize: '18px'}}>{ register }</Badge> : false
                            }
                            {
                                (date) ? <Badge bg="danger" style={{marginRight: '10px', fontSize: '18px'}}>{ moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') }</Badge> : false
                            }
                            {
                                (locationId) ? <Badge bg="danger" style={{marginRight: '10px', fontSize: '18px'}}>{ locations.filter(location=>location.id == locationId)[0].name }</Badge> : false
                            }
                            {
                                (roomName) ? <Badge bg="danger" style={{marginRight: '10px', fontSize: '18px'}}>{ roomName }</Badge> : false
                            }
                        </Col>
                    </Row>
                </Container>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Hora</th>
                            <th>Agendar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            hoursAvailability.map(vacancy=>{
                                return <tr>
                                    <td>{vacancy.time}</td>
                                    <td>{(vacancy.status==='vago') ? 
                                        (
                                            (loading == vacancy.time) ? <Spinner animation="border" />
                                            : <Button variant='dark' style={{textTransform: 'uppercase'}} onClick={()=>toSchedule(vacancy.time)}>Agendar</Button>
                                        )
                                        : <Icon className='vaga-marcada' onClick={()=>cancellation(vacancy.id, vacancy.time)} icon="bi:check-circle-fill" color='#067302' fontSize={'200%'} />}
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
                <Container>
                <h2 style={{textAlign: 'center', marginBottom: '40px', marginTop: '40px'}}>Alterar Agendamento</h2>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Hora</th>
                            <th>Local</th>
                            <th>Sala</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listSchedule.map(list=>{
                                return <tr>
                                    <td>{ `${moment(list.date, 'YYYY-MM-DD').format('DD/MM/YYYY')} - ${dateFn.getDayWeek(moment(list.date, 'YYYY-MM-DD').day())}` }</td>
                                    <td>{ list.time.substring(0, 5) }</td>
                                    <td>{ list.locationName }</td>
                                    <td>{ list.roomName }</td>
                                    <td>
                                        <CancellationSchedule id={list.id} dateCanceled={list.date} url={props.match.url} />
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </Container>
            </Container>
        </>
    );
}