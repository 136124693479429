import React, { useEffect } from 'react';
import Header from './../../Components/AdminHeader';
import { Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import http from './../../services/axios';
import moment from 'moment';
import Delete from './../../Components/DaysOffsetsDelete';
import Include from './../../Components/DaysOffsetsInclude';

export default function DaysOffsets(){
  const dispatch = useDispatch();

  const daysOffsets = useSelector(state => state.daysOffsetsState.daysOffsets);

  useEffect(()=>{
    http.daysOffsets().read()
      .then(result=>{
        dispatch({type: 'LOADING_DAYS_OFFSETS', payload: result.data});
      })
  }, []);

    return (
        <>
            <Header />
            <Container>
                <h2 style={{textAlign: 'center', marginBottom: '40px', marginTop: '40px'}}>Dias Ausentes</h2>
                <Include />
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                      <tbody>
                        {
                          daysOffsets.map(day=>{

                            let dayFormat = moment(day.date, 'YYYY-MM-DD').format('DD/MM/YYYY');

                            return <tr key={day.id}>
                              <td>{ dayFormat }</td>
                              <td>
                                <Delete id={day.id} date={dayFormat} />
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                  </Table>
            </Container>
        </>
    );
}