import './App.css';
import React, { useEffect, useState } from 'react';
import Pages from './Pages/Routes';
import Loading from './Components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider, useSelector } from 'react-redux';
import store from './store';
import Error from './Components/Error';

function App() {

  const loading = useSelector(state => state.loadingState.load);
  const isError = useSelector(state => state.notificationState.error);

  return (
    <div className="App">
      { loading ? <Loading /> : <Pages /> }
      { <Error /> }
    </div>
  );
}

function ProviderComponent(){
  return (
    <Provider store={ store }>
      <App />
    </Provider>
  );
}

export default ProviderComponent;
