import React, { useState } from 'react'
import { Navbar, Nav, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, Redirect } from 'react-router-dom';
import './style.css';
import { useDispatch } from 'react-redux';

export default function Header(){

    const [returnOptions, setReturnOptions] = useState(false);
    const dispatch = useDispatch();

    const logout = ()=>{
        localStorage.removeItem('account');
        dispatch({type: 'LOGOUT'});
        setReturnOptions(true);
    }

    return (
        <>
        {(returnOptions) ? <Redirect to='/login' /> : false}
        <Navbar id="header" expand="lg">
            <Navbar.Toggle aria-controls="navbarScroll" style={{marginLeft: '30px'}} />
            <Navbar.Collapse id="navbarScroll">
                <Nav
                className="mr-auto my-2 my-lg-0"
                navbarScroll
                >
                    <Navbar>
                        <Link to="/admin/download">Download</Link>
                    </Navbar>
                    <Navbar>
                        <Link to="/admin/cronograma/agendar">Agenda</Link>
                    </Navbar>
                    <Navbar>
                        <Link to="/admin/locais">Locais</Link>
                    </Navbar>
                    <Navbar>
                        <Link to="/admin/salas">Salas</Link>
                    </Navbar>
                    <Navbar>
                        <Link to="/admin/profissionais">Profissionais</Link>
                    </Navbar>
                    <Navbar>
                        <Link to="/admin/disponibilidade">Disponibilidade</Link>
                    </Navbar>
                    <Navbar>
                        <Link to="/admin/dias-ausentes">Dias ausentes</Link>
                    </Navbar>
                    <Navbar>
                        <Button onClick={logout} variant='link'>Sair</Button>
                    </Navbar>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        </>
    )
}