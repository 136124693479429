import Modal from 'react-bootstrap/Modal'
import React from 'react';
import {Button, Form} from 'react-bootstrap';
import http from './../../services/axios';
import { useDispatch } from 'react-redux';

export default function ProfessionalDelete(props) {
  const dispatch = useDispatch();

  const remove = ()=>{
    props.onHide();
    http.user().destroy(props.reg)
    .then(()=>dispatch({type: 'DELETE_USER', options: { reg: props.reg } }))
    .catch(error=>dispatch({type: 'ERROR', title: `Erro ao deletar profissional`, message: error.response.data}));
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Deletar profissional
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>{ `Deseja deletar o profissional ${props.reg}?` }</Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onHide}>Voltar</Button>
        <Button onClick={remove}>Deletar</Button>
      </Modal.Footer>
    </Modal>
  );
}
  

  