import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Button, Navbar, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import http from './../../services/axios';
import './style.css';
import { DesktopDatePicker, LocalizationProvider, DesktopTimePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { TextField, Stack, MenuItem } from '@mui/material';

function MaterialUITimePickers({time, setDate}) {

    const handleChange = (newValue) => {
      setDate(newValue ? moment(newValue).format('HH:mm') : '');
    };

    return (
      <LocalizationProvider dateAdapter={DateAdapter} locale={moment.locale('pt-br')}>
        <Stack spacing={3}>
          <DesktopTimePicker
            label="Time"
            inputFormat="HH:mm"
            value={moment(time, 'HH:mm')}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
    );
}

function MaterialUIPickers({date, setDate, label}) {

    const handleChange = (newValue) => {
      setDate(moment(newValue).format('YYYY-MM-DD'));
    };
  
    return (
      <LocalizationProvider dateAdapter={DateAdapter} locale={moment.locale('pt-br')}>
        <Stack spacing={3}>
          <DesktopDatePicker
            label={label}
            inputFormat="DD/MM/yyyy"
            value={date}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
    );
}

export default function TimeLineFilter(props){

    const [returnOptions, setReturnOptions] = useState(false);
    const [dateBegin, setDateBegin] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [time, setTime] = useState('');
    const [locationId, setLocationId] = useState(0);
    const [roomId, setRoomId] = useState(0);
    const dispatch = useDispatch();

    const locations = useSelector(state => state.locationState.locations);
    const rooms = useSelector(state => state.roomState.rooms);

    const filter = ()=>{
        http.schedule().readAll({ dateBegin, dateEnd, time, locationId, roomId })
            .then(result=>dispatch({type: 'LOADING_SCHEDULE', payload: result.data}))
            .catch(error=>dispatch({type: 'ERROR', title: `Erro ao buscar agenda`, message: error.response.data}));
    }

    useEffect(()=>{
        http.location().read()
            .then(result=>{
                dispatch({type: 'LOADING_LOCATION', newLocations: result.data});
            })
        if(locationId) {
            http.room().readFromLocation(Number(locationId))
                .then(result=>{
                    dispatch({type: 'LOADING_ROOM', payload: result.data});
                })
        }
    }, [locationId]);
    
    const logout = ()=>{
        localStorage.removeItem('account');
        dispatch({type: 'LOGOUT'});
        setReturnOptions(true);
    }

    return (
        <>
        {(returnOptions) ? <Redirect to='/login' /> : false}
        <Navbar id="timeline-filter" expand="lg" style={{textTransform: 'uppercase', margin: '5%'}} >
            <Navbar.Brand style={{margin: '1%'}}>
                Filtro:
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Container>
                    <Row xs={1} md={4} lg={7} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail" style={{color: 'white'}}>
                                    <MaterialUIPickers label="Data Inicial" date={dateBegin} setDate={setDateBegin} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail" style={{color: 'white'}}>
                                    <MaterialUIPickers label="Data Final" date={dateEnd} setDate={setDateEnd} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Local"
                                        value={locationId}
                                        onChange={e=>setLocationId(Number(e.target.value))}
                                        fullWidth
                                    >
                                        {[
                                            {id: 0, name: 'Selecione um local'}, ...locations]
                                                .map((location) => (
                                                    <MenuItem key={location.id} value={location.id}>
                                                        {location.name}
                                                    </MenuItem>
                                                )
                                            )
                                        }
                                    </TextField>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Sala"
                                            value={roomId}
                                            onChange={e=>setRoomId(Number(e.target.value))}
                                            fullWidth
                                        >
                                            {[
                                                {id: 0, name: 'Selecione uma sala'}, ...rooms]
                                                    .sort((a, b)=>{
                                                        if (a.name < b.name) {
                                                        return -1;
                                                        }
                                                        if (a.name > b.name) {
                                                        return 1;
                                                        }
                                                        return 0;
                                                    })
                                                    .map((room) => (
                                                        <MenuItem key={room.id} value={room.id}>
                                                            {room.name}
                                                        </MenuItem>
                                                    )
                                                )
                                            }
                                    </TextField>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <MaterialUITimePickers time={time} setDate={setTime} />
                                </Form.Group>
                            </Col>
                    </Row>
                    <Col style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <Form.Group className="mb-3" controlId="formBasicEmail" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <Button variant="success" onClick={filter} >APLICAR</Button>
                            <Button onClick={logout} style={{textTransform: 'uppercase', color: 'white', margin: '5%'}}>Sair</Button>
                        </Form.Group> 
                    </Col>
                </Container>
            </Navbar.Collapse>
        </Navbar>
        </>
    );
}