const INITIAL_STATE = {
    daysOffsets: []
}

const daysOffsetsReducer = (state = INITIAL_STATE, { type, payload, options }) => {
    switch(type){
        case 'CREATE_DAYS_OFFSETS':
            return { ...state, daysOffsets: [...state.daysOffsets, payload] };
        case 'LOADING_DAYS_OFFSETS':
            return { ...state, daysOffsets: payload };
        case 'DELETE_DAYS_OFFSETS':
            return { ...state, daysOffsets: state.daysOffsets.filter(day=>day.id != options.id) }
        default:
            return state;
    }
}

export default daysOffsetsReducer;