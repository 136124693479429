import { combineReducers } from 'redux';
import loadingReducer from './loadingReducer';
import notificationReducer from './notificationReducer';
import userReducer from './userReducer';
import locationReducer from './locationReducer';
import roomReducer from './roomReducer';
import scheduleReducer from './scheduleReducer';
import daysOffsetsReducer from './daysOffsetsReducer';
import availabilityReducer from './availabilityReducer';

export default combineReducers({
    loadingState: loadingReducer,
    notificationState: notificationReducer,
    userState: userReducer,
    locationState: locationReducer,
    scheduleState: scheduleReducer,
    roomState: roomReducer,
    daysOffsetsState: daysOffsetsReducer,
    availabilityState: availabilityReducer
});