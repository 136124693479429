const INITIAL_STATE = {
    logged: false,
    users: [],
    user: {}

}

const userReducer = (state = INITIAL_STATE, { type, user, token, payload, options }) => {
    switch(type){
        case 'LOGIN':
            return { ...state, logged: true, user, token };
        case 'LOGOUT':
            return { ...state, logged: false, user: '', token: '' };
        case 'CREATE_USER':
            return { ...state, users: [...state.users, payload] };
        case 'LOADING_USER':
            return { ...state, users: payload };
        case 'DELETE_USER':
            return { ...state, users: state.users.filter(user=>user.reg != options.reg) }
        case 'UPDATE_USER':
            return { ...state, users: state.users.map(user=>{
                if(user.reg === options.reg){
                    let newDataUser = user;
                    Object.keys(payload).forEach((field, index) => {
                        newDataUser[field] = payload[field];
                    });
                    return newDataUser;
                }
                return user;
                })
            }
        default:
            return state;
    }
}

export default userReducer;