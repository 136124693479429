const INITIAL_STATE = {
    rooms: []
}

const roomReducer = (state = INITIAL_STATE, { type, payload, options }) => {
    switch(type){
        case 'CREATE_ROOM':
            return { ...state, rooms: [...state.rooms, payload] };
        case 'LOADING_ROOM':
            return { ...state, rooms: payload };
        case 'DELETE_ROOM':
            return { ...state, rooms: state.rooms.filter(room=>room.id != options.roomId) }
        case 'UPDATE_ROOM':
            return { ...state, rooms: state.rooms.map(room=>{
                if(room.id === options.roomId){
                    let newDataRoom = room;
                    Object.keys(payload).forEach((field, index) => {
                        newDataRoom[field] = payload[field];
                    });
                    return newDataRoom;
                }
                return room;
                })
            }
        default:
            return state;
    }
}

export default roomReducer;